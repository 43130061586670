<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="900px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on" class="font-weight-bold"
          >Create new profile</v-btn
        >
      </template>
      <v-card>
        <v-row>
          <v-col cols="10">
            <span class="text-h5 font-weight-bold mx-3"
              >Create trainer's account</span
            >
          </v-col>
          <v-col cols="1">
            <v-icon color="red" @click="onClose" dark large dense>
              cancel
            </v-icon>
          </v-col>
          <v-col cols="1">
            <v-icon color="green" @click="createTrainer" dark large dense>
              done
            </v-icon>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="trainerForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <div class="text-h6 font-weight-bold mx-2">
                    First Name <span class="red--text">*</span>
                  </div>
                  <v-text-field
                    label=""
                    outlined
                    required
                    v-model="profile.firstName"
                    :rules="rules.firstName"
                  ></v-text-field>
                </v-col>
                <div class="text-h6 font-weight-bold mx-2">
                  Last name <span class="red--text">*</span>
                </div>
                <v-col cols="12">
                  <v-text-field
                    label=""
                    outlined
                    required
                    v-model="profile.lastName"
                    :rules="rules.lastName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div class="text-h6 font-weight-bold mx-2">
                    Primary email id <span class="red--text"></span>
                  </div>
                  <v-text-field
                    label=""
                    outlined
                    v-model="profile.primaryEmailId"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <div class="text-h6 font-weight-bold mx-2">
                    Linked In Profile Url <span class="red--text"></span>
                  </div>
                  <v-text-field
                    label=""
                    outlined
                    v-model="profile.linkedinProfileUrl"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-snackbar v-model="snackbar" :timeout="timeout" color="red">
                {{ text }}

                <template v-slot:action="{ attrs }">
                  <v-btn
                    outlined
                    color="black"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                  >
                    Close
                  </v-btn>
                </template>
              </v-snackbar>
              <v-snackbar
                :timeout="-1"
                :value="snackbar_linkedin"
                absolute
                bottom
                shaped
                rounded="pill"
              > Scrapping data takes time please wait for a while
              <v-progress-linear
                indeterminate
                color="green"
              ></v-progress-linear>
               
              </v-snackbar>
              
            </v-container>
            <small class="text-h8 font-weight-bold red--text">
              * indicates required field</small
            >
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex";
import { createWidgetMixin } from "vue-instantsearch";
const connectRefresh = (
  renderFn,
  unmountFn
) => (/* this widget has no parameters */) => ({
  init() {
    renderFn({ refresh: () => {} }, true);
  },

  render({ instantSearchInstance }) {
    const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);
    renderFn({ refresh }, false);
  },

  dispose() {
    unmountFn();
  }
});

export default {
  components: {},
  mixins: [createWidgetMixin({ connector: connectRefresh })],
  data: () => ({
    dialog: false,
    snackbar: false,
    text: "Enter a valid linkedin url",
    timeout: 10000,
    rules: {
      isRequired: [(v) => !!v || "Required"],
      firstName: [
        (v) => !!v || "First Name is empty",
        (v) =>
          (v && v.length <= 20) || "First Name must be less than 20 characters"
      ],
      lastName: [
        (v) => !!v || "Last Name is empty",
        (v) =>
          (v && v.length <= 20) || "Last Name must be less than 20 characters"
      ],
      email: [
        (v) => !!v || "Required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid"
      ]
    }
  }),
  computed: {
    ...mapState("trainerProfile", {
      profile: "createProfile",
      loading: "loading",
      snackbar_linkedin:"snackbar_linkedin"
    })
  },
  methods: {
    cb() {
      this.$refs.trainerForm.reset();
      this.state.refresh();
      this.dialog = false;
      this.deleteDialogOpen = false;
    },
    onClose() {
      this.$refs.trainerForm.reset();
      this.dialog = false;
    },
    async createTrainer() {
      if (this.$refs.trainerForm.validate()) {
        let trainerProfile = {
          firstName: this.profile.firstName,
          lastName: this.profile.lastName,
          primaryEmailId: this.profile.primaryEmailId,
          linkedinProfileUrl: this.profile.linkedinProfileUrl
        };
        // if (this.profile.linkedinProfileUrl) {
        //   if (this.profile.linkedinProfileUrl.includes("www.linkedin.com")) {
        //     let linkedinProfileUrl =this.profile.linkedinProfileUrl
        //     let splitUrl = this.profile.linkedinProfileUrl.split("/");
        //     if (splitUrl.length >= 5) {
        //       console.log("split url", splitUrl[4]);
        //       trainerProfile.linkedinProfileUrl = splitUrl[4];
        //       this.$store.dispatch("trainerProfile/getScrappedData", {
        //         trainerProfile,
        //         linkedinProfileUrl,
        //         cb: this.cb
        //       });
        //     } else {
        //       this.snackbar = true;
        //     }
        //   } else {
        //     this.snackbar = true;
        //   }
        // } else {
          await this.$store.dispatch("trainerProfile/createTrainer", {
            trainerProfile,
            cb: this.cb
          });
        //}
      }
    }
  }
};
</script>
