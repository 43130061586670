<template>
  <div id="search_page" v-if="access==accessFull">
    <ais-instant-search :search-client="searchClient" index-name="trainer_prod">
      <header class="search-header" id="header">
        <p class="header-title">
          Search for trainers based upon skills, industry ,department,course,
          and target audience
        </p>
        <ais-search-box
          placeholder="Search in trainer inventory"
          class="ais-search-box"
        >
          <template v-slot:submit-icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
              width="16"
              height="16"
            >
              <g
                fill="none"
                fill-rule="evenodd"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.67"
                transform="translate(1 1)"
              >
                <circle cx="7.11" cy="7.11" r="7.11"></circle>
                <path d="M16 16l-3.87-3.87"></path>
              </g>
            </svg>
          </template>
        </ais-search-box>
      </header>
      <main class="search-container">
        <div class="container-wrapper">
          <section class="container-filters">
            <div class="container-header">
              <h2>Filters</h2>
              <ais-clear-refinements data-layout="desktop">
                <template v-slot:resetLabel>
                  <div class="clear-filters">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      viewBox="0 0 11 11"
                    >
                      <g fill="none" fill-rule="evenodd" opacity=".4">
                        <path d="M0 0h11v11H0z"></path>
                        <path
                          fill="#000"
                          fill-rule="nonzero"
                          d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                        ></path>
                      </g>
                    </svg>
                    Clear filters
                  </div>
                </template>
              </ais-clear-refinements>
            </div>
            <div class="container-body">
              <ais-panel>
                <template v-slot:header>Primary skills</template>

                <template v-slot:default>
                  <ais-refinement-list
                    attribute="primarySkills"
                    searchable-placeholder="Search primary skill"
                    searchable
                    :show-more="showMore"
                    :show-more-limit="showMoreNumber"
                  />
                </template>
              </ais-panel>
              <ais-panel>
                <template v-slot:header>Rough skills</template>

                <template v-slot:default>
                  <ais-refinement-list
                    attribute="roughSkills"
                    searchable-placeholder="Search rough  skills"
                    searchable
                    :show-more="showMore"
                    :show-more-limit="showMoreNumber"
                  />
                </template>
              </ais-panel>
              <ais-panel>
                <template v-slot:header>Secodary skills</template>

                <template v-slot:default>
                  <ais-refinement-list
                    attribute="secondarySkills"
                    searchable-placeholder="Search secondary  skill"
                    searchable
                    :show-more="showMore"
                    :show-more-limit="showMoreNumber"
                  />
                </template>
              </ais-panel>
              <ais-panel>
                <template v-slot:header>Trainer journey status</template>

                <template v-slot:default>
                  <ais-refinement-list
                    attribute="journeyStatus"
                    searchable-placeholder="Search journey status"
                    searchable
                    :show-more="showMore"
                    :show-more-limit="showMoreNumber"
                  />
                </template>
              </ais-panel>
              <ais-panel>
                <template v-slot:header>Negotiated full day fees</template>
                <template v-slot:default>
                  <ais-range-input attribute="trainerNegotiatedFees">
                    <template
                      v-slot="{ currentRefinement, range, refine, canRefine }"
                    >
                      <VueSlider
                        :min="range.min"
                        :max="range.max"
                        :value="toValue(currentRefinement, range)"
                        :disabled="!canRefine"
                        :lazy="true"
                        :useKeyboard="true"
                        :enableCross="false"
                        tooltip="always"
                        :duration="0"
                        @change="refine({ min: $event[0], max: $event[1] })"
                      >
                        <template v-slot:dot="{ index, value }">
                          <div
                            :aria-valuemin="range.min"
                            :aria-valuemax="range.max"
                            :aria-valuenow="value"
                            :data-handle-key="index"
                            class="vue-slider-dot-handle"
                            role="slider"
                            tabindex="0"
                          />
                        </template>
                        <template v-slot:tooltip="{ value }">
                          {{ formatNumber(value) }}
                        </template>
                      </VueSlider>
                    </template>
                  </ais-range-input>
                </template>
              </ais-panel>
              <ais-panel>
                <template v-slot:header>Departments</template>
                <template v-slot:default>
                  <ais-menu-select attribute="targetDepartment" />
                </template>
              </ais-panel>
              <ais-panel>
                <template v-slot:header>Industry</template>
                <template v-slot:default>
                  <ais-menu-select attribute="targetIndustries" />
                </template>
              </ais-panel>
              <ais-panel>
                <template v-slot:header>Target audience</template>
                <template v-slot:default>
                  <ais-menu-select attribute="targetAudience" />
                </template>
              </ais-panel>
            </div>
          </section>
        </div>
        <section class="container-results">
          <header class="container-header container-options">
            <CreateTrainerDailoge/>
            <Refresh/>
            <ais-sort-by
              class="container-option"
              :items="[
                { value: 'instant_search', label: 'Name' },
                { value: 'instant_search_price_asc', label: 'Price ascending' },
                {
                  value: 'instant_search_price_desc',
                  label: 'Price descending'
                }
              ]"
            />
            <ais-hits-per-page
              class="container-option"
              :items="[
                {
                  label: '16 hits per page',
                  value: 16,
                  default:
                    getSelectedHitsPerPageValue() === 16 ||
                    !getSelectedHitsPerPageValue()
                },
                {
                  label: '32 hits per page',
                  value: 32,
                  default: getSelectedHitsPerPageValue() === 32
                },
                {
                  label: '64 hits per page',
                  value: 64,
                  default: getSelectedHitsPerPageValue() === 64
                }
              ]"
            />
          </header>
          <ais-hits class="my-5">
            <template v-slot:item="{ item }">
              <div @click="routeTo(item)" class="test_cursor">
                <v-card width="1030px" flat outlined class="mt-4">
                  <v-row no-gutters>
                    <v-col cols="3">
                      <!-- <v-row>
                        <div
                          class="text-h6 font-weight-bold mx-2 my-n1 text--primary"
                        >
                          {{ item.trainerId }}
                        </div>
                      </v-row> -->
                      <v-row no-gutters>
                        <div
                          class="text-h6 font-weight-bold ml-5 text--primary"
                        >
                          {{ item.firstName }} {{ item.middleName }}
                          {{ item.lastName }}
                        </div>
                      </v-row>
                      <v-row no-gutters v-if="item.primaryEmailId">
                        <div
                          class="text-h7 font-weight-bold ml-5  primary--text"
                        >
                          {{ item.primaryEmailId }}
                        </div>
                      </v-row>
                      <v-row no-gutters v-if="item.primaryPhoneNumber">
                        <div
                          class="text-h7 font-weight-bold ml-5  primary--text"
                        >
                          {{ item.primaryPhoneNumber }}
                        </div>
                      </v-row >
                      <v-row no-gutters>
                        <div
                          class="text-h8 font-weight-bold ml-5 my-1 text--primary"
                        >
                          Trainer journey status
                        </div>
                      </v-row>
                      <v-row no-gutters>
                        <div
                          class="text-h7 font-weight-bold ml-5  primary--text"
                        >
                         {{ item.journeyStatus }}
                        </div>
                      </v-row >
                      <v-row no-gutters>
                        <div
                          class="text-h8 font-weight-bold ml-5 my-1 text--primary"
                        >
                          Source
                        </div>
                      </v-row>
                      <v-row no-gutters>
                        <div
                          class="text-h7 font-weight-bold ml-5  primary--text"
                        >
                         {{ item.source }}
                        </div>
                      </v-row >
                      <!-- <v-row no-gutters>
                        <div
                          class="text-h8 font-weight-bold ml-5 text--primary"
                        >
                          Created by
                        </div>
                      </v-row>
                      <v-row no-gutters>
                        <div
                          class="text-h7 font-weight-bold ml-5  primary--text"
                        >
                         {{ item.createdBy}}
                        </div>
                      </v-row> -->
                       <v-row no-gutters>
                        <div
                          class="text-h8 font-weight-bold ml-5 my-1 text--primary"
                        >
                          Creation date
                        </div>
                      </v-row>
                      <v-row no-gutters>
                        <div
                          class="text-h7 font-weight-bold ml-5  primary--text"
                        >
                         {{new Date(item.creationDate).toLocaleString()}}
                        </div>
                      </v-row >
                      <v-row no-gutters v-if="item.trainerBand">
                        <div
                          class="text-h8 font-weight-bold ml-5 my-1 text--primary"
                        >
                          Trainer band
                        </div>
                      </v-row>
                      <v-row no-gutters v-if="item.trainerBand">
                        <div
                          class="text-h7 font-weight-bold ml-5  primary--text"
                        >
                          {{ item.trainerBand }}
                        </div>
                      </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="8">
                      <v-row>
                        <v-col cols="3">
                          <div class="text-h8 font-weight-bold primary--text">
                            Rough Skills:
                          </div>
                        </v-col>
                        <v-col cols="9">
                          <v-row class="my-n2" no-gutters>
                            <v-list
                              v-for="(primarySkill,
                              index) in item.roughSkills"
                              :key="index"
                            >
                              {{ primarySkill }} ,
                            </v-list>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <div class="text-h8 font-weight-bold primary--text">
                            Primary Skills:
                          </div>
                        </v-col>
                        <v-col cols="9">
                          <v-row class="my-n2" no-gutters>
                            <v-list
                              v-for="(primarySkill,
                              index) in item.primarySkills"
                              :key="index"
                            >
                              {{ primarySkill }} ,
                            </v-list>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <div class="text-h8 font-weight-bold primary--text">
                            Secondary skills:
                          </div>
                        </v-col>
                        <v-col cols="9">
                          <v-row class="my-n2" no-gutters>
                            <v-list
                              v-for="(primarySkill,
                              index) in item.secondarySkills"
                              :key="index"
                            >
                              {{ primarySkill }} ,
                            </v-list>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <div class="text-h8 font-weight-bold primary--text">
                            Target industries :
                          </div>
                        </v-col>
                        <v-col cols="9">
                          <v-row class="my-n2" no-gutters>
                            <v-list
                              v-for="(primarySkill,
                              index) in item.targetIndustries"
                              :key="index"
                            >
                              {{ primarySkill }} ,
                            </v-list>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <div class="text-h8 font-weight-bold primary--text">
                            Target deparment :
                          </div>
                        </v-col>
                        <v-col cols="9">
                          <v-row class="my-n2" no-gutters>
                            <v-list
                              v-for="(primarySkill,
                              index) in item.targetDepartment "
                              :key="index"
                            >
                              {{ primarySkill }} ,
                            </v-list>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row v-if="item.targetAudience">
                        <v-col cols="3">
                          <div class="text-h8 font-weight-bold primary--text">
                            Target audience :
                          </div>
                        </v-col>
                        <v-col cols="9">
                          <v-row class="my-n2" no-gutters>
                            <v-list
                              v-for="(primarySkill,
                              index) in item.targetAudience "
                              :key="index"
                            >
                              {{ primarySkill }} ,
                            </v-list>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3">
                          <div class="text-h8 font-weight-bold primary--text">
                            Negotaited price
                          </div>
                        </v-col>
                        <v-col cols="9">
                          <v-row no-gutters v-if="item.trainerNegotiatedFees">
                             ₹ {{item.trainerNegotiatedFees}}
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </template>
          </ais-hits>
          <footer class="container-footer">
            <ais-pagination :padding="2">
              <template
                v-slot="{
                  currentRefinement,
                  pages,
                  isFirstPage,
                  isLastPage,
                  refine,
                  createURL
                }"
              >
                <ul class="ais-Pagination-list">
                  <li
                    class="ais-Pagination-item ais-Pagination-item--previousPage ais-Pagination-item--disabled"
                    v-if="isFirstPage"
                  >
                    <span class="ais-Pagination-link">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        width="10"
                        height="10"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.143"
                        >
                          <path d="M9 5H1M5 9L1 5l4-4"></path>
                        </g>
                      </svg>
                    </span>
                  </li>

                  <li
                    class="ais-Pagination-item ais-Pagination-item--previousPage"
                    v-if="!isFirstPage"
                  >
                    <a
                      class="ais-Pagination-link"
                      :href="createURL(currentRefinement - 1)"
                      @click.prevent="refine(currentRefinement - 1)"
                      aria-label="Previous"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        width="10"
                        height="10"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.143"
                        >
                          <path d="M9 5H1M5 9L1 5l4-4"></path>
                        </g>
                      </svg>
                    </a>
                  </li>

                  <li
                    :class="
                      cx('ais-Pagination-item', 'ais-Pagination-item--page', {
                        'ais-Pagination-item--selected':
                          page === currentRefinement
                      })
                    "
                    v-for="page in pages"
                    :key="page"
                  >
                    <a
                      class="ais-Pagination-link"
                      :href="createURL(page)"
                      :style="{
                        fontWeight: page === currentRefinement ? 'bold' : ''
                      }"
                      @click.prevent="refine(page)"
                      >{{ page + 1 }}</a
                    >
                  </li>

                  <li
                    class="ais-Pagination-item ais-Pagination-item--nextPage"
                    v-if="!isLastPage"
                  >
                    <a
                      class="ais-Pagination-link"
                      :href="createURL(currentRefinement + 1)"
                      @click.prevent="refine(currentRefinement + 1)"
                      aria-label="Next"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        width="10"
                        height="10"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.143"
                        >
                          <path d="M1 5h8M5 9l4-4-4-4"></path>
                        </g>
                      </svg>
                    </a>
                  </li>

                  <li
                    class="ais-Pagination-item ais-Pagination-item--nextPage ais-Pagination-item--disabled"
                    v-if="isLastPage"
                  >
                    <span class="ais-Pagination-link">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 10 10"
                        width="10"
                        height="10"
                      >
                        <g
                          fill="none"
                          fill-rule="evenodd"
                          stroke="#000"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="1.143"
                        >
                          <path d="M1 5h8M5 9l4-4-4-4"></path>
                        </g>
                      </svg>
                    </span>
                  </li>
                </ul>
              </template>
            </ais-pagination>
          </footer>
        </section>
      </main>
    </ais-instant-search>
  </div>
  <!-- <main class="container">
    <Search />
  </main> -->
</template>
<script>
import algoliasearch from "algoliasearch/lite";
import "./searchpage.css";
import "./theme.css";
import CreateTrainerDailoge from '../../../components/CreateTrainerDailog/createTrainerDailog.vue'
import VueSlider from "vue-slider-component";

import cx from "classnames";
import Refresh from '../../../components/algoliaRefresh.vue'
import { mapState } from "vuex";
const indexName=process.env.ALGOLIA_INDEX_NAME
//import Search from "../../../components/search/search.vue";
export default {
  name: "search",
  components: {
    //  Search
    VueSlider,
   
    CreateTrainerDailoge,
    Refresh 
  },
  computed:{
    ...mapState("userAuthenticaiton", {
      access: "access",
    })
  },
  data() {
    return {
      cx,
      indexName,
      accessFull:"full",
      searchClient: algoliasearch(
        "BI87J2Z4BG",
        "4ddb5a672e9aa3888a18845acdfad9c0"
      ),
      showMore: true,
      showMoreNumber: 12
    };
  },
  created() {
    if(!this.access || this.access !=this.accessFull){
     this.$router.push('/pages/authentication/login')
    }
    this.$store.dispatch('userAuthenticaiton/checkLogin')
    this.$store.dispatch('trainerProfile/resetAllState')
    
  },
  methods: {
    formatNumber(value) {
      return Number(value).toLocaleString();
    },
    toValue(value, range) {
      return [
        typeof value.min === "number" ? value.min : range.min,
        typeof value.max === "number" ? value.max : range.max
      ];
    },
    getSelectedHitsPerPageValue() {
      const [, hitsPerPage] =
        document.location.search.match(/hitsPerPage=([0-9]+)/) || [];
      return Number(hitsPerPage);
    },
    routeTo(item) {
      console.log("rooute to callled", item);
      this.$store.dispatch('trainerProfile/getTrainerProfile',item)
    }
    // toValue(value, range) {
    //   return [
    //     value.min !== null ? value.min : range.min,
    //     value.max !== null ? value.max : range.max
    //   ];
    // }
  }
};
</script>
<style></style>
