<template>
  <v-btn @click="refresh" outlined small>refresh</v-btn>
</template>

<script>
import { createWidgetMixin } from 'vue-instantsearch';

const connectRefresh = (
  renderFn,
  unmountFn
) => (/* this widget has no parameters */) => ({
  init() {
    renderFn({ refresh: () => {} }, true);
  },

  render({ instantSearchInstance }) {
    const refresh = instantSearchInstance.refresh.bind(instantSearchInstance);
    renderFn({ refresh }, false);
  },

  dispose() {
    unmountFn();
  },
});

export default {
  mixins: [createWidgetMixin({ connector: connectRefresh })],
  methods: {
    refresh() {
      console.log('state refesh call ---' ,this.state);
      this.state.refresh();

    },
  },
};
</script>